.About {
  margin: 5%;
}

.About-content {
  background-color: rgba(255, 255, 255, 0.75);
  color: black;
  text-align: justify;
  padding: 5%;
  font-size: 18pt;
  line-height: 1.5;
  box-shadow: 0 2px 10px black;
}

.About-title {
  font-size: 42pt;
  text-align: left;
  margin: 3rem 0;
}

.About ul {
  margin-right: 10%;
}

.About-photo {
  width: 100%;
  aspect-ratio: 3/4;
  object-fit: cover;
  object-position: center;
  margin-top: 20px;
}

.About-divider {
  width: 100%;
  height: 2px;
  background-color: black;
  margin: 3rem 0;
}

.About-bio-text-container {
  margin: 0 2rem;
}

.About-bio-container {
  display: grid;
  grid-template-columns: 30% 70%;
}

.About-bio-photo {
  margin: 0 !important;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  margin-top: 20px;
}

.About-link {
  color: black;
  transition: 0.15s;
}

.About-link:hover {
  color: var(--gold);
  transition: 0.15s;
}

@media (min-width: 768px) {
  .About-photo {
    width: 35%;
    float: right;
    margin: 0 0 50px 50px;
  }
}

@media (max-width: 767px) {
  .About {
    font-size: 12pt;
  }

  .About-title {
    font-size: 36pt;
    margin: 1rem 0 2rem 0;
  }

  .About-content {
    font-size: 12pt;
  }

  .About-bio-container {
    grid-template-columns: 100%;
  }

  .About-bio-text-container {
    margin: 2rem 0;
  }
}

@media (max-width: 300px) {
  .About-title {
    font-size: 24pt;
  }

  .About-content {
    font-size: 10pt;
  }
}
