.Landing {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
}

.Landing-overlay {
  background-color: var(--green-transparent);
  font-family: "Bitter", serif;
  color: white;
  height: 100%;
  padding: 50px;
}

.Landing-logo {
  padding: 2rem;
  border: solid 3px white;
  box-shadow: 5px 5px var(--gold), 5px -5px var(--gold), -5px 5px var(--gold),
    -5px -5px var(--gold);
  height: 30%;
  width: min(fit-content, 100%);
  object-fit: contain;
}

.Landing-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.Landing-text {
  height: 50%;
}

.Landing-button-container {
  height: 15%;
}

.Landing-text h2 {
  font-size: 32pt;
  padding: 1.5rem 0 1rem 0;
  margin: 0;
}

.Landing-button {
  font-size: 14pt;
  font-weight: bold;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0);
  color: var(--gold);
  border: solid 1px var(--gold);
  transition: 0.25s;
  width: fit-content;
  margin: auto;
}

.Landing-button:hover {
  background-color: var(--gold);
  color: white;
  transition: 0.25s;
}

@media (max-width: 767px) {
  .Landing-text h2 {
    font-size: 20pt;
  }
}

@media (max-width: 950px) {
  .Landing-logo {
    height: 25%;
    width: 100%;
  }
}

@media (max-width: 300px) {
  .Landing-overlay {
    padding: 25px;
  }

  .Landing-logo {
    padding: 1rem;
  }
}
