.Podcast {
  margin: 5%;
}

.Podcast-content-main {
  background-color: rgba(255, 255, 255, 0.75);
  color: black;
  text-align: justify;
  padding: 5%;
  font-size: 18pt;
  line-height: 1.5;
  box-shadow: 0 2px 10px black;
}

.Podcast-title {
  margin-bottom: 2rem;
}

.Podcast-header {
  margin: 3rem 0;
  text-align: center;
}

.Podcast-header-content {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  row-gap: 15px;
  margin: 1rem 0;
}

.Podcast-content {
  display: grid;
  grid-template-columns: 65% 35%;
}

.Podcast-content-episodes {
  margin-right: 50px;
}

.Podcast-photo {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.Podcast-search-form {
  margin: 0 auto 2rem 0;
  padding: 0 1rem;
  border: var(--green) solid 2px;
}

.Podcast-search-input {
  width: 100%;
  margin: 1rem 0;
  font-size: 14pt;
}

.Podcast-search-button {
  margin-bottom: 1rem;
  background-color: #00000000;
  border: var(--green) solid 1px;
  color: var(--green);
  font-size: 16pt;
}

.Podcast-search-button:hover {
  background-color: var(--green);
  color: white;
  transition: 0.15s;
}

.Podcast-search-button-left {
  margin-right: 0.5rem;
}

.Podcast-search-button-right {
  margin-left: 0.5rem;
}

.Podcast-search-button-container {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.Podcast-search-indicator {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
}

.Podcast-no-episodes {
  width: 100%;
  text-align: center;
}

.Podcast-footer {
  width: 100%;
  margin-top: 5%;
}

@media (max-width: 950px) {
  .Podcast-search-input,
  .Podcast-search-button {
    font-size: 12pt;
  }
}

@media (max-width: 767px) {
  .Podcast-header {
    margin: 2rem 0;
  }

  .Podcast-search-input,
  .Podcast-search-button {
    font-size: 12pt;
  }

  .Podcast-header-content {
    grid-template-columns: 50% 50%;
    font-size: 12pt;
  }

  .Podcast-content {
    grid-template-columns: 100%;
  }

  .Podcast-content-episodes {
    margin-right: 0;
  }

  .Podcast-search-form {
    grid-template-columns: 100%;
    margin-top: 2rem;
  }

  .Podcast-search-button {
    margin-top: 0;
  }
}

@media (max-width: 300px) {
  .Podcast-search-form {
    grid-template-columns: 100%;
  }

  .Podcast-search-button {
    margin-top: 0;
  }

  .Podcast-search-input,
  .Podcast-search-button {
    font-size: 10pt;
  }
}
