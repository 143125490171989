.NavBar {
  font-family: "Bitter", serif;
  height: fit-content;
  color: var(--light-blue);
}

#brand-logo-container {
  margin: 0;
  color: white;
  transition: 0.15s;
}

#brand-logo-container:hover {
  color: var(--gold);
  transition: 0.15s;
}

.NavBar-item {
  margin-left: 30px;
  margin-right: 30px;
}

.NavBar-link {
  color: inherit;
}

.nav-link {
  position: relative;
  transition: 0.15s;
  padding: 1rem !important;
}

.nav-link:hover {
  color: var(--gold) !important;
  background-color: var(--green-transparent) !important;
  cursor: pointer;
  transition: 0.15s;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border: 1px solid #00000000;
  width: 100%;
  height: 100%;
  transition: 0.15s;
}

.nav-link:hover::after {
  border: 1px solid var(--gold);
  transition: 0.15s;
}

#Nav {
  background-color: var(--green);
  padding: 0 40px;
  box-shadow: 0 2px 10px black;
}

@media (max-width: 767px) {
  #Nav {
    padding: 0 20px;
  }
}
