.SubscriptionLink {
  width: 100%;
  text-align: center;
  font-size: 14pt;
}

.SubscriptionLink-link {
  color: white;
  transition: 0.15s;
}

.SubscriptionLink-link:hover {
  color: var(--gold);
  transition: 0.15s;
}

@media (max-width: 767px) {
  .SubscriptionLink {
    font-size: 12pt;
  }
}

@media (max-width: 300px) {
  .SubscriptionLink {
    font-size: 10pt;
  }
}
